@font-face {
    font-family: Artifakt-regular;
    src: url('../fonts/Artifakt Element Regular.woff2');
    font-display: swap;
}
@font-face {
    font-family: Artifakt-bold;
    src: url('../fonts/Artifakt Element Bold.woff2');
    font-weight: bold;
    font-display: swap;
}
@font-face {
    font-family: Artifakt-book;
    src: url('../fonts/Artifakt Element Book.woff2');
    font-display: swap;
}
*{
    font-family: Artifakt-regular;
    list-style:none;
    margin: 0;
    padding: 0;
    text-decoration: none;
}
html{
    scroll-behavior: smooth;
}